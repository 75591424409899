import React from 'react';

import { Layout } from '../../components/layouts';
import { SEO } from '../../components/common';
import { OrderSummarySection } from '../../components/sections';
import { CountryCode } from '../../utils/constants';

const SummaryPage = (): JSX.Element => {
  return (
    <Layout countryCode={CountryCode.SG}>
      <SEO title="Order Summary" />
      <OrderSummarySection />
    </Layout>
  );
};

export default SummaryPage;
